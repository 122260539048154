<template>
  <v-card
    :loading="bills === null"
    class="d-flex d-flex-column fill-height align-stretch"
  >
    <v-card-title>{{ $t('invoices') }}</v-card-title>
    <v-card-subtitle>{{ $t('invoicesAndBalancesAreUpdated') }}</v-card-subtitle>
    <v-card-text>
      <div class="mb-3">
        <div class="caption">
          {{ $t('CURRENTBALANCE') }}
        </div>
        <div
          class="headline green--text"
          style="font-weight: bolder;"
        >
          {{ balanceFormatted }}
        </div>
      </div>
      <div class="caption">
        {{ $t('STATE') }}
      </div>
      <div
        class="headline green--text"
        style="font-weight: bolder;"
      >
        {{ $t('OPEN') }}
      </div>
    </v-card-text>
    <v-spacer class="flex" />
    <v-card-actions class="">
      <v-btn
        link
        class="flex"
        to="/billing/invoices"
        color="primary"
      >
        {{ $t('viewAllInvoices') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: 'BillingInvoices',
    computed: {
        balanceFormatted() {
          return this.$store.getters.formatAmountByCurrency(this.balance)
        },
        balance() {

            const balance = {
                amount: 0,
                currency_code: 'ISK'
            }

            if(this.bills !== null) {
                for(let i in this.bills) {

                    const bill = this.bills[i]

                    if(bill.balance && bill.balance.amount !== null) {
                        balance.amount = balance.amount + bill.balance.amount
                    }
                }
            }

            return balance
        },
        bills() {
            const bills = this.$store.state.bills

            if(bills === null) {
                return null
            }

            return bills.filter(bill => bill.state === 'pending' || bill.state === 'billing_pending_payment').sort(function (a, b) {

                if (a.created > b.created) {
                    return -1
                }

                if (b.created > a.created) {
                    return 1
                }

                return 0
            })
        }
    }
}
</script>