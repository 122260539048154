<template>
  <v-card>
    <v-card-title>{{ $t('creditsUsage') }}</v-card-title>
    <v-card-subtitle>{{ $t('listOfVariousCreditsUsed') }}</v-card-subtitle>
    <v-list 
      :loading="creditUseage === null" 
      v-if="creditUseage === null || creditUseage.length > 0"
    >
      <template v-for="(use, index) in creditUseage">
        <v-list-item :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ use.created | moment("dddd, MMMM Do YYYY HH:mm:ss") }}</v-list-item-title>
            <v-list-item-subtitle>{{ formattedAmount(use.creditsBefore) }} - {{ use.unitOfChange }} = {{ formattedAmount(use.creditsAfter) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-card-text v-else>
      {{ $t('noUsageDetectedYet') }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    name: 'BillingCreditUse',
    data() {
      return {
        creditUseage: null
      }
    },
    mounted() {
      return this.$store.dispatch('getBillingCreditUseage').then(creditUseage => {
        this.creditUseage = creditUseage.sort((c1, c2) => {
          return c2.created - c1.created
        })
      }) 
    },
  methods: {
      formattedAmount(price) {
        if(typeof price !== 'undefined' && price !== null) {
          return this.$store.getters.formatAmountByCurrency(price)
        }
        return ''
      }
  }
}
</script>