<template>
  <v-container>
    <v-row class="d-flex align-stretch">
      <v-col cols="6">
        <BillingCredit class="flex" />
      </v-col>
      <v-col cols="6">
        <BillingInvoices class="flex flex-column" />
      </v-col>
      <v-col cols="12">
        <BillingCreditUsage />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import BillingCredit from "@/components/billing/BillingCredit"
  import BillingInvoices from "@/components/billing/BillingInvoices"
  import BillingCreditUsage from "@/components/billing/BillingCreditUsage"

  export default {
    name: 'Billing',
    components: {
      BillingCredit,
      BillingInvoices,
      BillingCreditUsage
    },
    data: () => ({
      processing: false,
    }),
    computed: {
      bills: function() {

        const bills = this.$store.state.bills

        if(bills === null) {
          return null
        }

        return bills.filter(bill => bill.state === 'pending' || bill.state === 'billing_pending_payment').sort(function (a, b) {

          if (a.created > b.created) {
            return -1
          }

          if (b.created > a.created) {
            return 1
          }

          return 0
        })
      }
    },
    created() {
      this.processing = true
      this.$store.dispatch('getBills').finally(() => {
        this.processing = false
      })
    }
  }
</script>
